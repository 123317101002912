<template>

  <div class="container">
    <edit-header
      entity="purchase order"
      :error.sync="error"
      :invalid.sync="invalid"
      :invalid-message="invalidMessage"
      :submitted.sync="submitted"
      :isNew="true"/>
    <div class="row">
      <div class="col-12">
        <div class="bg-white">
          <div class="card border-0 shadow-none">
            <div class="card-header">
              <div class="card-title">
                Roster purchase order
              </div>
              <div class="card-options">
                <a
                  v-if="!edit"
                  href="#"
                  class="btn btn-link"
                  @click.prevent="edit = true">Edit filter</a>
              </div>
            </div>
            <div class="card-body">
              <dimmer :active="searching">
                <purchase-order-filter
                  ref="filter"
                  :edit="edit"
                  :filter.sync="filter"
                  @valid="data => applyFilter(data)"
                  @export="data => exportForecast(data)"
                />
              </dimmer>
            </div>
          </div>
          <div class="mt-4">
            <form
              v-if="!edit"
              ref="form"
              class="validation bg-light"
              novalidate
              @submit.prevent="submitPurchasOrder">
              <div class="row">
                <div class="col">
                  <div class="card border-0">
                    <div class="card-header border-0 pb-4">
                      <div class="card-title">
                        Showing {{filteredResults.length}} articles
                        <div class="small text-muted">Selected {{selectedArticles.length}} articles</div>
                      </div>
                      <div class="card-options">
                        <table-filters
                          class="d-flex align-items-center"
                          :options="tableFilterList"
                          :filters.sync="resultFilters"
                          :items="items"></table-filters>
                      </div>
                    </div>
                    <div class="table-responsive mb-0" style="max-height: 35rem; overflow-y: auto;">
                      <table class="table table-outline table-bordered table-striped table-hover table-vcenter card-table sticky-table dataTables_wrapper">
                        <thead class="thead-light leading-none text-center">
                          <tr>
                            <th class="w-1 border-right-0 py-4 pl-4">
                              <input
                                type="checkbox"
                                class="align-middle"
                                @input="toggleAllCheckbox($event.target.checked)">
                            </th>
                            <th
                              class="border-left-0 font-weight-bold text-left py-4"
                              :class="column === 'article_name' ? `sorting_${sort}` : 'sorting'"
                              @click="() => sortColumn('article_name', 'string')">
                              Article
                            </th>
                            <th class="font-weight-bold py-4">Supplier</th>
                            <th class="text-nowrap font-weight-bold py-4">Pack <br/>size</th>
                            <th
                              v-b-tooltip.hover
                              title="Ordering unit of measure"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              OUM <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Minimum order quantity"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              MOQ <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Suggested order quantity"
                              style="width: 14%;"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              SOQ <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Stock on order"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              SOO <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              class="font-weight-bold py-4 pr-4"
                              :class="column === 'replenishment_lead_time' ? `sorting_${sort}` : 'sorting'"
                              @click="() => sortColumn('replenishment_lead_time')">
                              Lead <br/>time
                            </th>
                            <th class="font-weight-bold py-4">Delivery <br/>date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="article in filteredResults"
                            :key="article.article_id"
                            :class="{'table-success': article.isSelected}">
                            <td class="border-right-0 pl-4">
                              <input
                                v-model="article.isSelected"
                                type="checkbox"
                                class="align-middle">
                            </td>
                            <td class="border-left-0" style="width: 20%">
                              <div class="d-flex flex-column">
                                <small><router-link :to="`/articles/${article.article_id}`" target="_blank">#{{article.article_id}}</router-link></small>
                                <strong>{{article.article_name}}</strong>
                                <small class="text-muted text-capitalize">{{article.material_type_name.split('-').join(' ')}}</small>
                              </div>
                            </td>
                            <td>{{article.supplier_name}}</td>
                            <td class="text-center">{{article.pack_size}}</td>
                            <td>{{article.replenishment_ordering_uom_name}}</td>
                            <td class="text-center">{{numeral(article.replenishment_minimum_order_quantity).format('0,0[.]00')}}</td>
                            <td>
                              <input
                                v-model="article.decided_order_quantity"
                                type="number"
                                step="any"
                                placeholder="Enter quantity"
                                :required="article.isSelected"
                                class="form-control">
                            </td>
                            <td class="text-center">{{numeral(article.stock_on_order).format('0,0[.]00')}}</td>
                            <td class="text-center">{{article.replenishment_lead_time}}</td>
                            <td class="text-nowrap text-center">
                              {{moment(article.delivery_date).format('dddd')}} <br/>
                              {{moment(article.delivery_date).format('DD MMM YYYY')}}
                            </td>
                          </tr>
                          <tr v-if="!filteredResults.length">
                            <td class="text-center" colspan="11">
                              <div class="d-flex align-items-center justify-content-center">
                                No articles to purchase. <button class="btn btn-link pl-0" @click.prevent="edit = true">Search again.</button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <edit-footer
                    :dirty="dirty"
                    entity="purchase order"
                    :isNew="true"
                    :submitting="submitting">
                  </edit-footer>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import PurchaseOrderFilter from './filters/PurchaseOrderFilter';
import TableFilters from './filters/TableFilters';
import edit from '@/mixins/edit';
import sort from '@/mixins/sort';
import Dimmer from '@/components/Dimmer.vue';
import {purchaseOrderRoster, purchaseOrders, articleForecast} from '@/services';
import moment from 'moment-timezone';
import download from '@/mixins/download';

export default {
  components: {
    Dimmer,
    EditFooter,
    EditHeader,
    PurchaseOrderFilter,
    TableFilters,
  },
  mixins: [
    download,
    edit,
    sort,
  ],
  data() {
    return {
      edit: true,
      feature: true,
      filter: {
        category: null,
        days: [],
        purchaseType: '',
        suppliers: [],
        weeklyMenu: null,
      },
      items: [],
      resultFilters: {
        suppliers: [],
      },
      searching: false,
      tableFilterList: {
        supplier_name: {
          label: 'supplier',
          model: 'suppliers',
        },
      },
    };
  },
  computed: {
    filteredResults() {
      let results = this.items || [];

      if (this.resultFilters.suppliers.length) { results = results.filter(item => this.resultFilters.suppliers.some(supplier => supplier === item.supplier_name)); }

      return results;
    },
    selectedArticles() {
      return this.filteredResults.filter(item => item.isSelected);
    },
  },
  methods: {
    async exportForecast(type) {
      try {
        this.searching = true;

        const response = await articleForecast.forecastExport(type, {
          weekly_menu_id: this.filter.weeklyMenu.id,
          delivery_days: this.filter.days.map(d => d.id),
          category_id: this.filter.category.id,
        });
        this.download(response);
      }
      catch (e) {
        this.errorMessage = 'There is no data to download';
      }
      finally {
        this.searching = false;
      }
    },
    async applyFilter() {
      this.searching = true;

      const params = {

        category: this.filter.category.id,
        delivery_day: this.filter.days.map(day => day.id),
        type: this.filter.purchaseType,
        weekly_menu: this.filter.weeklyMenu.id,
      };

      if (this.filter.suppliers.length) { params.supplier = this.filter.suppliers.map(supplier => supplier.id); }

      try {
        const result = (await purchaseOrderRoster.getByParameters(params)).items;

        this.items = result.map(item => {
          return Object.assign(item, {

            decided_order_quantity: item.suggested_order_quantity || null,
            isSelected: false,
          });
        });
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.searching = false;
        this.edit = false;
      }
    },
    completed() {
      if (!this.invalid) {
        // reset data fields
        Object.assign(this.$data, {

          edit: true,
          feature: true,
          filter: {

            category: null,
            days: [],
            purchaseType: '',
            suppliers: [],
            weeklyMenu: null,
          },
          items: [],
          resultFilters: {

            suppliers: [],
          },
          searching: false,
        });

        this.$refs.filter.$v.$reset();
      }
    },
    async submitPurchasOrder() {
      this.error = false;
      this.response = {

        data: null,
        error: {},
      };
      this.invalid = false;
      this.invalidMessage = '';
      this.submitted = false;

      const form = this.$refs.form;
      const valid = await this.validate() && form.checkValidity();

      if (valid) {
        this.submitting = true;

        try {
          const payload = Object.entries(this.selectedArticles.reduce((combined, article) => {
            (combined[article.supplier_id] = combined[article.supplier_id] || []).push(article);
            return combined;
          }, {}))
            .flatMap(item => {
              return {

                articles: item[1].map(article => {
                  return {
                    article_id: article.article_id,
                    category_id: article.category_id,
                    decided_qty: article.decided_order_quantity,
                    oum_id: article.replenishment_ordering_uom_id,
                    qb_name: article.replenishment_quickbook_name,
                    suggested_qty: article.suggested_order_quantity,
                  };
                }),
                delivery_date: moment(item[1][0].delivery_date).format('YYYY-MM-DD'),
                delivery_days: this.filter.days.map(day => day.id),
                lead_time: item[1][0].replenishment_lead_time,
                supplier_id: item[0],
                weekly_menu_id: this.filter.weeklyMenu.id,
                purchase_type: this.filter.purchaseType,
              };
            });

          await purchaseOrders.place({purchase_orders: payload || []});

          this.completed();
          this.submitted = true;
        }
        catch (ex) {
          if (ex.response && ex.response.status === 422) {
            this.invalid = true;
            this.response = {data: this.response.data || this.item, error: ex.response.data.errors};
          }
          else {
            this.error = true;
            throw ex;
          }
        }
        finally {
          this.submitting = false;
        }
      }
      else { this.invalid = true; }

      form.classList.toggle('was-validated', this.invalid);
      window.scrollTo(0, 0);
    },
    toggleAllCheckbox(value) {
      this.filteredResults.forEach(item => {
        item.isSelected = value;
      });
    },
  },
};
</script>

<style scoped>
    .big-checkbox >>> span > .fe {
        font-size: 2rem;
    }

    .sticky-table thead th {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 20;
        border-right-width: 1px;
        border-left-width: 1px;
    }

    .sticky-table thead th:after {

        right: 2px;
        bottom: 1rem;
    }
    .sticky-table thead th:before {

        right: 2px;
        top: 1rem;
    }
</style>
